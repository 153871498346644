.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App__SectionBar {
   padding: 10px;
}

.App__ProfileDesc h1 {
   width: 100%;
   margin-bottom: 10px;
   padding: 0px;
}

.App__ProfileDesc h6 {
   width: 100%;
   margin: 0px;
   padding: 0px;
}

.App__DescExceptPic {
   display: flex;
   flex-direction: column;
   text-align: left;
}

.App__AreaOfInterests {
   display: flex;
   justify-content: space-between;
}

@media screen and (max-width: 1000px) {
   .App__AreaOfInterests {
      display: flex;
      flex-direction: column;
   }
}

.App__DivideIntoTwo {
   padding-top: 35px;
   margin: auto;
   margin-left: 7%;
   display: flex;
   width: 80%;
   min-height: 100vh;
}

.App__LeftSection {
   /* display: flex;
   flex-decoration: column; */
   width: 70%;
   border-right: 1px solid lightgray;
}

.App__ProfileDesc {
   display: flex;
   /* height: 125px; */
}

.App__Table {
   display: flex;
   flex-direction: column;
}

.App__RightSection {
   margin-left: auto;
   margin-right: auto;
}

.App__RightSectionTitle h4 {
   align-items: left;
   text-align: left;
   font-weight: normal;
   font-family: Arial, sans-serif;
}

.App__RightSectionTitle {
 margin-bottom: 10;
}

.App__RightSectionTable {
   display: flex;
   align-items: center;
   margin-right: auto;
   flex-direction: column;
   margin-bottom: 20;
   padding-bottom: 10;
}

.App__TableTitles h4 {
   padding-right: 40px;
   font-weight: normal;
}

.App__TableTitles h4:hover {
   text-decoration: underline;
   cursor: pointer;
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.Input {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 10%;
}

.Input__Input {
   align-items: center;
   margin: auto;
   width: 400px;
}

.loginScreen {
   max-width: 300px;
   padding: 40px 70px 65px 70px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 5%;
}

.loginScreen > form > h1 {
   text-align: left;
   margin-bottom: 25px;
}

.loginScreen > form {
   display: grid;
   flex-direction: column;
   margin-bottom: 65px;
}

.loginScreen > form > input {
   outline-width: 0;
   height: 40px;
   margin-bottom: 14px;
   border-radius: 5px;
   border: 1px solid lightgray;
   padding: 5px 15px;
   color: #000000;
   background: white;
}

.loginScreen > form > select {
   outline-width: 0;
   height: 40px;
   margin-bottom: 14px;
   border-radius: 5px;
   border: 1px solid lightgray;
   padding: 5px 15px;
   color: #000000;
   background: white;
}

.loginScreen > form > input:focus {
   color: #000000;
   border: 2px solid lightblue;
   font-size: 16px;
}

.loginScreen > form > button {
   padding: 16px 20px;
   font-size: 1rem;
   color: #000000;
   border-radius: 5px;
   background-color: #ffffff;
   /* outline: 1px solid lightgray; */
   font-weight: 600;
   border: 1px solid lightgray;
   cursor: pointer;
   margin-top: 20px;
}

.loginScreen > form > button:hover {
   border: 2px solid lightblue;
}

.support {
   display: flex;
   color: #b3b3b3;
   justify-content: space-between;
   font-size: 12px;
}

.support > input {
   width: 15px;
   height: 15px;
}

.remember {
   display: flex;
   align-items: center;
   margin-bottom: 5px;
   font-size: 13px;
}

.remember > span {
   margin-right: 1px;
   height: 25px;
}

.login__facebook {
   display: flex;
   align-items: center;
   width: 100%;
   color: #737373;
   padding-bottom: 10px;
}

.login__facebook img {
   width: 20px;
   height: 20px;
}

.login__facebook span {
   margin-right: 10px;
   font-size: 13px;
}

.login__facebook span a {
   text-decoration: none;
   color: #737373;
}

.sign__up {
   color: #737373;
   font-size: 16px;
}

.sign__up a {
   color: #fff;
   font-size: 16px;
   text-decoration: none;
}

.signin_button {
   margin-bottom: 10px;
}

.signup_button {
}

.terms {
   color: #8c8c8c;
   font-size: 13px;
}

.terms a {
   text-decoration: none;
   color: #0071eb;
}

.terms a:hover {
   cursor: pointer;
   text-decoration: underline;
}

.login__facebook span a {
   text-decoration: none;
   color: #737373;
}

.loginScreen__gray {
   color: #737373;
}

.loginScreen__help {
   color: #737373;
   font-size: 13px;
}

.loginScreen__help:hover {
   color: #737373;
   cursor: pointer;
   text-decoration: underline;
}

.loginScreen__link {
   color: white;
   text-decoration: none;
}

.loginScreen__link:hover {
   color: white;
   cursor: pointer;
   text-decoration: underline;
}

.nav_logo {
   margin-top: 20px;
   margin-left: 50px;
}

.login__background {
   position: relative;
   height: 100%;
   background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
   background-size: cover;
}

.loginScreen__gradient {
   width: 100%;
   z-index: 1;
   height: 37vh;
}
