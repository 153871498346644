.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App__TableTitles {
   margin-bottom: 15px;
   background-color: rgba(204, 204, 204, 0.25);
   text-align: left;
   display: flex;
   width: 100%;
}

.App__SectionBar {
   padding: 10px;
}

.App__ProfileDesc h1 {
   font-family: Arial, sans-serif;
   font-weight: normal;
   width: 100%;
   margin-bottom: 10px;
   padding: 0px;
}

.App__ProfileDesc h6 {
   font-family: Arial, sans-serif;
   font-weight: normal;
   width: 100%;
   margin: 0px;
   padding: 0px;
}

.centerDiv {
   font-size: "50px";
   position: "absolute";
   top: "50%";
   left: "50%";
   transform: translate(-50%, -50%);
}

.App__DescExceptPic {
   display: flex;
   flex-direction: column;
   text-align: left;
   margin-right: 5%;
   margin-bottom: 15px;
}

.App__AreaOfInterests {
   display: flex;
   justify-content: space-between;
}

.App__AreaOfInterests a {
   margin-right: 10px;
}

@media screen and (max-width: 1000px) {
   .App__AreaOfInterests {
      display: flex;
      flex-direction: column;
   }
}

.App__DivideIntoTwo {
   padding-top: 35px;
   margin: auto;
   display: flex;
   width: 90%;
   min-height: 100vh;
}

.App__LeftSection {
   /* display: flex;
    flex-decoration: column; */
   margin-right: 25px;
   width: 70%;
   border-right: 1px solid lightgray;
}

.App__ProfileDesc {
   display: flex;
   margin-bottom: 10px;
   /* height: 125px; */
}

.App__Table {
   margin-right: 5%;
   display: flex;
   flex-direction: column;
}

.App__RightSection {
   display: flex;
   flex-direction: column;
   margin-bottom: 5%;
}

.App__TableTitles h4 {
   padding-right: 40px;
   font-weight: normal;
}

.App__TableTitles h4:hover {
   text-decoration: underline;
   cursor: pointer;
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}
