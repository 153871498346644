.Navbar-header {
   background-color: rgba(204, 204, 204, 0.25);
   padding: 25px; /*difference between margin and padding: 
					padding -> edit inside the component
					margin -> edit outside the component*/
   border-bottom: 1px solid lightgray;
   object-fit: contain; /*no matter how big or small the container gets, 
                      keep the aspect ratio of the image smae*/
   display: flex;
   align-items: center;
   text-align: center;
   padding-left: 45%;
   justify-content: space-between;
   font-family: Arial, sans-serif;
}

.Navbar-links > a {
   text-decoration: none;
   font-size: 20px;
   margin-left: 25px;
   color: white;
   font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Navbar-links > a:hover {
   cursor: pointer;
   text-decoration: underline;
}

.Navbar-links > button {
   margin-left: 25px;
   padding: 8px 10px 8px 10px;
   background-color: orange;
   font-size: 20px;
   color: aliceblue;
   font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
   border: 1px solid lightgray;
}

.Navbar-links > button:hover {
   cursor: pointer;
}
